import React from 'react'
import Contactus from '../Components/Contactus'


const Contact = () => {
  return (
    <div>
        <Contactus/>
        </div>
  )
}

export default Contact